import React, {useEffect, useRef, useState} from "react";
import './home-mobile.scss';
import LoadingCapabilities from "./LoadingCapabilities";
import Intro from "./Intro";
import Reach from "./Reach";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {PAGE_MOBILE} from "../../../constants";
import {Swiper, SwiperSlide} from "swiper/react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers";
import {ProjectModel} from "../../../models/Project";
import {useLocation} from "react-router-dom";
import ProjectMobileItem from "../ListProject/ProjectMobileItem";
import FooterMobile from "../FooterMobile";

const HomeMobile = () => {
  const swiperRef = useRef<any>(null);
  const location = useLocation();
  const listProject = useSelector((state: RootState) => state.projectList);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number | null>(null);
  const [footer, setFooter] = useState(PAGE_MOBILE.LOADING);

  useEffect(() => {
    if (location.state) {
      const projectActive: ProjectModel = location.state;
      const index = listProject.findIndex(item => item.id === projectActive.id);
      if (index > -1) {
        swiperRef.current.slideToLoop(2 + index, 0);
      }
    }
  }, [location.pathname]);

  const changeToPageLast = () => {
    swiperRef.current.slideToLoop(PAGE_MOBILE.REACH + listProject.length - 1);
  };

  const _handleNextStep = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const changeSwiper = (swiper: any) => {
    setCurrentSlideIndex(swiper.realIndex);
    setFooter(swiper.realIndex);
  };

  const _handlePrevStep = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const _renderHeader = () => {
    if (currentSlideIndex !== PAGE_MOBILE.LOADING
      && currentSlideIndex !== PAGE_MOBILE.INTRO
      && currentSlideIndex !== (PAGE_MOBILE.REACH + listProject.length - 1)
    ) {
      return (
        <div className="header-slide-container">
          <p className='text-title-mobile'>
            Experiments
          </p>
          <p className={'description'}>"To experiment is to invite challenges, and sometimes, chaos."</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const _renderProjectItem = (item: ProjectModel, index: number) => {
    return (
      <SwiperSlide virtualIndex={index + 2} key={index}>
        <ProjectMobileItem
          index={index}
          project={item}
          key={item.id}
          handlePrevStep={_handlePrevStep}
          handleNextStep={_handleNextStep}
          goToReach={changeToPageLast}
        />
      </SwiperSlide>
    )
  };

  const _renderFooter = () => {
    return (
      <FooterMobile
        footerType={footer}
        handlePrevStep={_handlePrevStep}
        handleNextStep={_handleNextStep}
        goToLast={changeToPageLast}
      />
    )
  };

  return (
    <div className="home-intro-mobile">
      {_renderHeader()}
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        className='swiper-container'
        ref={swiperRef}
        onSwiper={(swiper: any) => (swiperRef.current = swiper)}
        onSlideChange={changeSwiper}
        loop
        onClick={() => {
        }}
      >
        <SwiperSlide virtualIndex={PAGE_MOBILE.LOADING}>
          <LoadingCapabilities
            key={PAGE_MOBILE.LOADING}
            handleNextStep={_handleNextStep}
          />
        </SwiperSlide>
        <SwiperSlide virtualIndex={PAGE_MOBILE.INTRO}>
          <Intro
            key={PAGE_MOBILE.INTRO}
            footerType={PAGE_MOBILE.INTRO}
            handleNextStep={_handleNextStep}
            handlePrevStep={_handlePrevStep}
          />
        </SwiperSlide>
        {listProject.map((item, index) => {
          return _renderProjectItem(item, index)
        })}
        <SwiperSlide virtualIndex={PAGE_MOBILE.REACH + listProject.length}>
          <Reach
            key={PAGE_MOBILE.REACH}
            footerType={PAGE_MOBILE.REACH}
            handleNextStep={_handleNextStep}
            handlePrevStep={_handlePrevStep}
          /></SwiperSlide>
      </Swiper>
      {_renderFooter()}
    </div>
  )
};

export default HomeMobile;

import React, {useEffect, useState} from 'react'
import ImageItem from './ImageItem'
import Lottie from 'react-lottie';
import {getSettingGifDefault} from "../../../ultils/getSettingGif"

interface Props {
  widthTd: number;
  gif1: string;
  gif2: string;
  time1: number;
  time2: number;
  className1: string;
  className2: string;
  timeShow?: number;
}

export default function ImageBox(props: Props) {
  const {widthTd, gif1, gif2, time1, time2, className1, className2, timeShow} = props;
  const [show, setShow] = useState(false);
  const [animation, setAnimation] = useState('fadeInOut 0.5s ease-in-out');

  const _renderImage = (src: string, className: string) => {
    const animationData = require(`../../../assets/Json/${src}.json`);
    return (
      <div
        className={className}
      >
        <Lottie
          options={getSettingGifDefault(animationData)}
          style={{
            animation: animation,
            borderRadius: 10,
          }}
          width={'100%'}
          height={widthTd - 20}
          isClickToPauseDisabled
        />
      </div>
    )
  };

  useEffect(() => {
    if (timeShow) {
      setTimeout(() => setShow(true), timeShow)
    } else {
      setShow(true)
    }
  }, []);

  const changeAnimation = (value: string) => {
    setAnimation(value)
  };
  return (
    show ?
      <td className={'td-table-container'} style={{width: widthTd, height: widthTd}}>
        <ImageItem
          element1={_renderImage(gif1, className1)}
          element2={_renderImage(gif2, className2)}
          time1={time1}
          time2={time2}
          changeAnimation={changeAnimation}
        />
      </td>
      :
      <td className={'td-table-container'} style={{width: widthTd, height: widthTd}}/>
  )
}

export const SIZE_CONTENT_TYPE = {
  CHANGE_SIZE: 'CHANGE_SIZE'
}

export const CHANGE_LOADING = 'CHANGE_LOADING'

export const LIFT_GIF_TYPE = {
  INIT_LIST: 'INIT_LIST',
  CHANGE_GIF_ITEM: 'CHANGE_GIF_ITEM',
}

export const LIST_GIF_ERROR_TYPE = {
  ADD_GIF_ERROR: 'ADD_GIF_ERROR',
  REMOVE_GIF_ERROR: 'REMOVE_GIF_ERROR',
}

export const LIST_PROJECT_TYPE = {
  CHANGE_LIST: 'CHANGE_LIST'
}

export const IS_FISRT_TYPE = {
  CHANGE_IS_FISRT: 'CHANGE_IS_FISRT'
}
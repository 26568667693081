import { useSelector} from 'react-redux';
import {RootState} from '../../../redux/reducers';
import { isSafari } from 'react-device-detect';
import ButtonRight from './ButtonRight';
import {MAIL_CONFIG} from '../../../config';

const ContactInfo = () => {
    const widthContent = useSelector((state: RootState) => state.sizeContent.width);
    const heightContent = useSelector((state: RootState) => state.sizeContent.height);
    const size = heightContent / 9 + widthContent / 16;

    const _goList = () => {
        window.location.href = `mailto:${MAIL_CONFIG}`;
      };

    return (
        <div className={'content-right-box'}>
            <div className={isSafari ?'right-info-container-safari' :'right-info-container'}>
            <p className='text-gray'>{'< Reach >'}</p>
            <p>195 Pearl's Hill Terrace
                <br/>
                #03-76 Singapore 168976
            </p>
            <p className='text-gray'>{'< or >'}</p>
            <ButtonRight goList={_goList} size={size}/>
            </div>
        </div>
    )
  };
  
  export default ContactInfo
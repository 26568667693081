import { useState } from 'react';
interface Props {
  onLoad?: () => void | undefined;
  src: string,
  alt: string,
  className?: string,
  style?: any;
  onClick?: () => void;
}
const ImageBase = (props: Props) => {
  const { src, alt, className, style, onClick, onLoad } = props;
  const [loaded, setLoaded] = useState(false);

  const _handleLoad = () => {
    if (onLoad) {
      onLoad()
    }
    setLoaded(true);
  };

  return (
    <>
      <img
        src={src}
        alt={alt}
        className={className}
        style={{ opacity: 0, width: 0, height: 0, position: 'absolute' }}
        onClick={onClick}
        loading={"lazy"}
        onLoad={_handleLoad}
      />
      {loaded &&
        <img
          src={src}
          alt={alt}
          className={className}
          style={style}
          onClick={onClick}
          loading={"lazy"}
        />
      }

    </>
  )
}

export default ImageBase;

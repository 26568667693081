import { SIZE_CONTENT_TYPE } from "../type";

export interface SizeContentType {
  width: number;
  height: number;
}

const initState: SizeContentType = {
  width: 0,
  height: 0,
};

function sizeContent(state = initState, action: any) {
  const  payload : SizeContentType  = action.payload
  switch (action.type) {
    case SIZE_CONTENT_TYPE.CHANGE_SIZE:
      return payload;
    default:
      return state;
  }
};

export default sizeContent;
import React, { useState, useEffect, useRef } from 'react';

type CustomRandomRevealProps = {
  previous?: string;
  next?: string;
  interval?: number;
  delay?: number;
  caret?: Element;
  onComplete?: () => void;
};

const CustomRandomReveal: React.FC<CustomRandomRevealProps> = ({
  previous = "",
  next = "",
  interval = 1,
  delay = 1,
  caret = null,
  onComplete = () => {}
}) => {
  const [currentTitle, setCurrentTitle] = useState(previous);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setCurrentTitle(previous); // Set to previous title when component mounts or updates

    const hidePrevious = () => {
      let index = previous.length + 1;
      if (caret)
      {
        caret.classList.add('pause');
      }
      intervalRef.current = setInterval(() => {
        index -= 1;
        setCurrentTitle(prev => prev.slice(0, index)); // smth idk
        if (index == 0) {
          if (intervalRef.current) clearInterval(intervalRef.current);
          showNext();
        }
      }, interval);
    };

    const showNext = () => {
      let index = 0;
      intervalRef.current = setInterval(() => {
        index += 1;
        setCurrentTitle(next.slice(0, index));
        
        if (index == next.length) {
          if (intervalRef.current) clearInterval(intervalRef.current);
          if (caret)
          {
            caret.classList.remove('pause');
          }
          onComplete(); // Call the onComplete callback when done
        }
      }, interval);
    };

    timeoutRef.current = setTimeout(hidePrevious, delay * 500);

    // Cleanup function to clear intervals and timeouts
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [previous, next, interval, delay, onComplete]);

  return <span>{currentTitle}</span>;
};

export default CustomRandomReveal;

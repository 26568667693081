import React from 'react';
import { Layout } from "antd";
import TextFooter from '../../../component/Desktop/TextFooter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import Lottie from 'react-lottie';
import { getSettingGifDefault } from '../../../ultils/getSettingGif';
import * as animationData from '../../../assets/Json/FLOATINGMAN.json';

const { Footer } = Layout;

export default function FooterDesktop() {
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);

  const _renderGif = () => {
    return (
      <div
        className={'gif-image'}
      >
        <Lottie
          options={getSettingGifDefault(animationData)}
          width={'60%'}
          height={'auto'}
          isClickToPauseDisabled
        />
      </div>
    )
  };

  return (
    <Footer
      className='footer-desktop-container'
      style={{
        height: heightContent / 10,
        width: widthContent,
      }}
    >
      <div className="footer-info-container"
        style={{
          height: heightContent / 10,
          width: widthContent,
        }}>
        <div className={'footer-info-copyright'}>
          <TextFooter text={'CONCOCTING CHAOS'} />
          <TextFooter text={'TRUST THE PROCESS'} />
        </div>
        <div className="line-footer"
          style={{
            width: `${widthContent / 2 - 100}px`
          }} />
        {_renderGif()}
        <div className="line-footer"
          style={{
            width: `${widthContent / 2 - 100}px`
          }} />
      </div>
    </Footer>
  )
}

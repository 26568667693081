import CustomRandomReveal from '../../../ultils/CustomRandomReveal';
import { useState } from 'react';


const Intro = () => {           
  
  const _renderTextName = (titles : string[]) => {
    const [count, setCount] = useState(0);
    const caret = document.getElementsByClassName("typewriter-container")[0]

    return (
      <span className="typewriter-container pause">
        <CustomRandomReveal
          previous={titles[count]}
          next={titles[count == titles.length - 1? 0 : count + 1]}
          interval={100}
          delay={2}
          caret={caret}
          onComplete={()=> {
            if (count == titles.length - 1)
            {
              setCount(0);
            }
            else
            {
              setCount(count+1);
            }
          }}
        />
      </span>
    )
  };


  return (
    <div className='content-left-box'>
      <div className="box-left-content">
        <p className='text-gray'>{`< Intro >`}</p>
        <p>
          We run on experimentation and creativity. 
          Invented from Advertising and Production spheres, 
          The Other Lab thrives on materialising ideas with a team of highly skilled<span style={{whiteSpace: 'pre-wrap'}}> </span>{_renderTextName(['creatives', 'suits', 'developers', 'programmers', 'artists', 'illustrators', 'designers'])}
        </p>
        <p className={'text-gray'}>{'<>'}</p>
      </div>
    </div>
  )
};

export default Intro;

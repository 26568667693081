import { useNavigate } from "react-router-dom";
import { RouterName } from "../../../enum/RouterEnum";
import Lottie from 'react-lottie';
import * as animationData from '../../../assets/Json/TOLLOGO2.json';
import { getSettingGifDefault } from "../../../ultils/getSettingGif";
import './textHeader.scss' 

const TextHeader = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate(RouterName.dashboard);
  };

  return (
    <div className={'image-content-home'}>
      <Lottie
        options={getSettingGifDefault(animationData)}
        isClickToPauseDisabled={true}
      />
      <div className="shadow"></div>
    </div>
  )
}

export default TextHeader;

import './text-footer.scss';
interface Props {
  text: string;
}
const TextFooter = (props: Props) => {
  const { text } = props;
  return (
    <p className="text-footer">
      {text}
    </p>
  )
};

export default TextFooter;

import React from 'react';
import TextHeader from '../../../component/Desktop/TextHeader';
import { Layout } from "antd";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
const { Header } = Layout;

export default function HeaderDesktop() {
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);
  return (
    <Header className='header-desktop-container'
      style={{
        height: heightContent / 10,
        width: widthContent,
      }}>
      <div className={'header-info-container'}
        style={{
          height: heightContent / 10,
        }}
      >
        <div className="line-header" />
        <TextHeader />
        <div className="line-header" />
      </div>
    </Header>
  )
}

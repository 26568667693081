import './list-project.scss';
import { useSelector } from 'react-redux';
import { RootState } from "../../../redux/reducers";
import { ProjectModel } from '../../../models/Project';
import { useNavigate } from 'react-router-dom';
import LottiePlayer from './LottiePlayer';
import { RouterName } from '../../../enum/RouterEnum';

interface Props {
  project: ProjectModel;
  index: number;
}

const ProjectItem = (props: Props) => {
  const { project, index } = props;
  const height = useSelector((state: RootState) => state.sizeContent.height);
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const widthTd = height * 0.8 / 3;
  const navigate = useNavigate();

  const gotoDetail = () => {
    let charExits = /\W/g;
    const path = project.title.replaceAll(" ", "_").replace(charExits, '');
    navigate(`${RouterName.projectDetail}/${path}`, {
      state: project
    });
  };

  const checkAli = () => {
    let ali: 'left' | 'right' = 'left';
    switch (index % 4) {
      case 0:
      case 1:
        ali = 'right';
        break;
      case 2:
      case 3:
        ali = 'left';
        break;
      default:
        break;
    }
    return ali;
  };
  const align = checkAli();

  const _renderImageProject = () => {
    return (
      <div
        className={'image-project-box'}
        style={{
          width: widthTd,
          height: widthTd,
          borderRadius: '15px'
        }}
      >
        {/* <LottiePlayer link={project.image} className={'lottie-player-container2'} /> */}
        <video className='video-player'
              autoPlay 
              loop muted 
              key={index} 
              playsInline 
              src={project.image}
            />
      </div>
    )
  };

  const _renderInfoLeft = () => {
    return (
      <div className='card-item-right' style={{
        textAlign: align,
        width: widthTd,
        height: widthTd,
        fontSize: widthContent / 160
      }}>
        <p className='text-client'>EXPERIMENT</p>
        <p className='project-title' style={{
          fontSize: widthContent / 90
        }}>{project.detailTitle.toUpperCase()}</p>
        <p className='text-client'>SERVICES</p>
        <div className='text-services'>{project.services.map((item, index) => {
          return <p className='text-services-value' key={index}>{item} <br /> </p>
        })}</div>

      </div>
    )
  };

  return (
    <div className={'card-box-container'}>
      <div
        className='card-item-container'
        style={{
          flexDirection: align === 'right'
            ? 'row'
            : 'row-reverse',
        }}>
        {_renderInfoLeft()}
        {_renderImageProject()}
        <div className={'overlay'}>
          <button
            className="centered-button"
            onClick={gotoDetail}
            style={{
              fontSize: widthContent / 130
            }}
          >
            VIEW THE WORK
          </button>
        </div>
      </div>

    </div>
  )
};

export default ProjectItem;

import { IS_FISRT_TYPE } from "../type";

const initState: boolean = true;

function isFirstLoad(state = initState, action: any) {
  const  payload : boolean  = action.payload
  switch (action.type) {
    case IS_FISRT_TYPE.CHANGE_IS_FISRT:
      return payload;
    default:
      return state;
  }
};

export default isFirstLoad;
import './project-mobile-detail.scss';
import {WarningOutlined} from "@ant-design/icons";
import * as animationData from '../../../assets/Json/BLOB.json';
import Lottie from 'react-lottie';
import {getSettingGifDefault} from "../../../ultils/getSettingGif";
import {ProjectModel} from "../../../models/Project";
import ImageBase from '../../../elements/Image';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouterName } from '../../../enum/RouterEnum';
import { RandomReveal } from 'react-random-reveal';

const ProjectMobileDetail = () => {
  const location = useLocation();
  const [project, setProject] = useState({} as ProjectModel);
  const navigate = useNavigate();

  useEffect(() => {
    if(location.state) {
      setProject(location.state);
    }
  }, [location.state]);

  const goBack = () => {
    navigate(`${RouterName.dashboard}`, {
      state: project
    });
  };

  useEffect(() => {
    const handleScroll = (e: any) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('scroll', handleScroll, {passive: false});

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _renderTextLoad = (title: string) => {
    return (
      <RandomReveal
        isPlaying
        duration={2}
        characters={title}
        revealDuration={1}
        onComplete={() => ({ shouldRepeat: true, delay: 5 })}
      />
    )
  };

  // Sean Ngo: Displaying video name for debugging purpose
  const _displayLink = (link : string) => {
    const lastIndex = link.lastIndexOf("-");
    let display = "";
    if(lastIndex !== -1){
      display = link.substring(lastIndex + 1);
    }
    
    return (
      <>{display}</>
    );
  };
  
  const _renderImageProject = (link: string, index: number) => {
    return (
      <div>
        <lottie-player
          autoplay
          loop
          mode="normal"
          src={link}
          key={index}
          style={{
            padding: '10px',
            width: "100%"
          }}
        />  
      </div>
      
    )
  };

  const _renderImage = (link: string, index: number) => {
    return (
      <ImageBase
        src={link}
        alt={""}
        className={'project-image'}
        key={index}
      />
    )
  };

  const _renderVideo = (link : string, index : number) => {
    return (
      <video className={'project-image'} 
              autoPlay 
              loop muted 
              key={index} 
              playsInline 
              src={link}
      />
    )
  };

  return (
    <div className={'project-mobile-detail'}>
      <div className={'project-description-container'} id={'header-project-detail-mobile'}>
        <p className={'text-experiment'}>EXPERIMENT</p>
        <p className={'text-name-project'}>{project?.detailTitle}</p>
        <p className={'text-description-project'}>{project?.detailDescription}</p>
      </div>
      {project
        && project.detailImages
        && project.detailImages.map((item, index) => {
          if (item.includes('.json')) {
            return _renderImageProject(item, index)
          } else if (item.includes('.mp4')) {
            return _renderVideo(item, index)
          } else {
            return _renderImage(item, index)
          }
        })}
      <div className='end-project-item'>
        <WarningOutlined className='icon-warning'/>
        End of Experiment
      </div>
      <div className='project-mobile-detail-footer'>
        <div className='image-mobile-footer'>
          <Lottie
            options={getSettingGifDefault(animationData)}
            width={'100%'}
            height={'auto'}
            isClickToPauseDisabled
          />
        </div>
        <button className='button-back-to-top' onClick={goBack}>
          <span className={'icon-flash-end'}>{"<<< "}</span>
          {_renderTextLoad('Back to Experiments')}
        </button>
      </div>
    </div>
  )
};

export default ProjectMobileDetail;

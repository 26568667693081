import "@lottiefiles/lottie-player";
import {useEffect, useState } from "react";
import './page-detail.scss';

interface Props {
  link: string;
}

const ProjectLottie = (props: Props) => {
  const {link} = props;
  const [linkGif, setLinkGif] = useState(link);

  useEffect(() => {
    setLinkGif(link)
  }, [link]);

  return (
    <lottie-player
      autoplay
      loop
      mode="normal"
      src={linkGif}
      key={linkGif}
    ></lottie-player>
  )
};

export default ProjectLottie;

import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import SiderLeft from '../SiderLeft';
import SiderRight from '../SiderRight';
import { Outlet } from 'react-router-dom';
export default function ContentDesktop() {
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);
  const width = (widthContent - (heightContent * 16 / 15 + 160)) / 2;

  return (
    <div className='content-home-container'
      style={{
        height: heightContent * 0.8,
        marginTop: heightContent * 0.1,
        width: widthContent,
      }}
    >
      <SiderLeft />
      <div className={'content-home'} style={{
        width: heightContent * 0.8 * 4 / 3 + 80,
        marginLeft: width,
        marginRight: width,
      }}>
        <Outlet></Outlet>
      </div>
      <SiderRight/>
    </div>
  )
}

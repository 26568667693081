const Processing = () => {
  return (
    <div className='content-left-box' style={{
      borderBottom: 'none'
    }}>
      <div className="box-left-content">
        <p className='text-gray'>{'< Trust The Process >'}</p>
        <p>
          To experiment is to
          invite challenges, and sometimes,
          chaos.
          <br />
          <br />
          And now, we're inviting you
          to the lab to watch it all happen.
        </p>
        <p className='text-gray'>{'<>'}</p>
        <p
          style=
          {{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          Awaiting next steps ...<span className="span-text-loading">{'>>>'}</span></p>
      </div>
    </div>
  )
};

export default Processing;

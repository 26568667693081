import { ProjectModel } from "../../models/Project";
import { LIST_PROJECT_TYPE } from "../type";

const initState: ProjectModel[] = [];

function projectList(state = initState, action: any) {
  const  payload : ProjectModel[]  = action.payload
  switch (action.type) {
    case LIST_PROJECT_TYPE.CHANGE_LIST:
      return payload;
    default:
      return state;
  }
};

export default projectList;

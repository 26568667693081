import React, { useEffect } from 'react';
import {RandomReveal} from 'react-random-reveal';
import {MAIL_CONFIG} from '../../../config';
import {PAGE_MOBILE} from '../../../constants';
import ButtonRight from '../../../layout/Desktop/SiderRight/ButtonRight';
import FooterMobile from '../FooterMobile';

interface Props {
  footerType: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
}

export default function Reach(props: Props) {
  const {handlePrevStep, handleNextStep} = props;

  useEffect(() => {
    const handleScroll = (e: any) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('scroll', handleScroll, {passive: false});

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _renderTextLoad = (title: string) => {
    return title
  };

  const _goList = () => {
    window.location.href = `mailto:${MAIL_CONFIG}`;
  };

  const _renderContent = () => {
    return (
      <div className='intro-container'>
        <p className='text-gray'>{`< Reach >`}</p>
        <p className='description-intro'>
          {_renderTextLoad('195 Pearl’s Hill Terrace #03-76 ')}
          {_renderTextLoad('Singapore 168976')}
        </p>
        <p className={'text-gray'}>{'< or >'}</p>
        <div style={{marginLeft: '-40px', marginTop: '5px'}}>
          <ButtonRight
            goList={_goList}
            size={200}
          />
        </div>
      </div>
    )
  };

  return (
    <div className={'div-loading-container'}>
      {_renderContent()}
      {/*<FooterMobile*/}
      {/*  footerType={PAGE_MOBILE.REACH}*/}
      {/*  handlePrevStep={handlePrevStep}*/}
      {/*  handleNextStep={handleNextStep}*/}
      {/*/>*/}
    </div>
  )
}

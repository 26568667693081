import React, { useState } from 'react';
import { RandomReveal } from 'react-random-reveal';
import { MAIL_CONFIG } from '../../../config';

interface Props {
  goList: () => void;
  size: number;
}
export default function ButtonRight(props: Props) {
  const { goList, size } = props;
  const [show, setShow] = useState(false);

  const changeShowEffect = (isShow: boolean) => {
    setShow(isShow);
  };

  const _renderTextItem = (text: string, isLast?: boolean) => {
    return (
      <span style={{ marginRight: isLast ? '0' : '10px' }}>
        <RandomReveal
          isPlaying
          duration={1}
          characters={text}
          revealDuration={1}
        />
      </span>
    )
  }
  return (
    <button
      className='button-right'
      onClick={goList}
      onMouseEnter={() => changeShowEffect(true)}
      onMouseLeave={() => changeShowEffect(false)}
      style={{
        fontSize: `${size * 0.06}px`
      }}
    >
      {show
        ? <span>
          {_renderTextItem("LET'S CREATE CHEMISTRY")}
        </span>
        : MAIL_CONFIG
      }
    </button>
  )
}

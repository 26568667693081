import React, { useEffect, useState } from 'react';

interface Props {
  element1: React.ReactNode;
  element2: React.ReactNode;
  time1: number;
  time2: number;
  changeAnimation: (value: string) => void;
}

export default function ImageItem(props: Props) {
  const { element1, element2, time1, time2, changeAnimation } = props;
  const totalTime = time1 + time2 + 10;
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  useEffect(() => {
    changeImageShow();
    const intervalId = setInterval(changeImageShow, (totalTime) * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const changeImageShow = () => {
    initAnimation();
    setTimeout(() => change1(), time1 * 1000);
    setTimeout(() => change2(), (time1 + 5) * 1000);
    setTimeout(() => change1(), (time1 + 5 + time2) * 1000);
  };

  const initAnimation = () => {
    setShow1(true);
    setShow2(false);
    changeAnimation('fadeIn 0.5s ease-in-out');
  };

  const change1 = () => {
    changeAnimation('fadeOut 0.5s ease-in-out');
    setTimeout(() => {
      setShow1(false);
      setShow2(false);
    }, 500)
  };

  const change2 = () => {
    setShow1(false);
    setShow2(true);
    changeAnimation('fadeIn 0.5s ease-in-out');
  };

  return (
    <>
      {show1 && element1}
      {show2 && element2}
    </>
  )
}

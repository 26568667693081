import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFoundRouter from "./NotFoundRouter"
import Desktop from "../layout/Desktop";
import Content from "../component/Desktop/HomeContent";
import ListProject from "../component/Desktop/ListProject";
import ProjectDetail from "../component/Desktop/ProjectDetail";

const RouterDom = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFoundRouter/>}/>
        <Route path={'/'} element={<Desktop/>}>
          <Route path={''} element={<Content/>}/>
          <Route path={'list-project'} element={<ListProject/>}/>
          <Route path={'project-detail/:id'} element={<ProjectDetail/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default RouterDom

import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from '../reducers/index';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['projectGifReducer', 'projectList'],
  blacklist: ['isFirstLoad']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware: any = [];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
);

const persistor = persistStore(store);

export { store, persistor };

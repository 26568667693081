import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFoundRouter from "./NotFoundRouter"
import Mobile from "../layout/Mobile";
import HomeMobile from "../component/Mobile/Home";
import ProjectMobileDetail from "../component/Mobile/ProjectDetailMobile";

const RouterMobile = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFoundRouter/>}/>
        <Route path={'/'} element={<Mobile/>}>
          <Route path={''} element={<HomeMobile/>}/>
          <Route path={'project-detail/:id'} element={<ProjectMobileDetail/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default RouterMobile

import React, { useEffect } from 'react';
import {IMAGE_CIRCLES} from '../../../assets';
import {RandomReveal} from 'react-random-reveal';
import Lottie from 'react-lottie';
import * as animationData from '../../../assets/Json/FLOATINGMAN.json';
import FooterMobile from '../FooterMobile';
import {PAGE_MOBILE} from '../../../constants';

interface Props {
  footerType: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
}

export default function Intro(props: Props) {
  const {handlePrevStep, handleNextStep} = props;

  useEffect(() => {
    const handleScroll = (e: any) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('scroll', handleScroll, {passive: false});

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _renderTextLoad = (title: string) => {
    return title
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const screenWidth = window.innerWidth || document.documentElement.clientWidth;

  const _renderContent = () => {
    return (
      <div className='intro-container-mobile'>
        <p className='text-gray'>{`< Intro >`}</p>
        <p className='description-intro-mobile' >
          {_renderTextLoad('We run on experimentation and creativity.')}
          {_renderTextLoad('Invented from Advertising and Production spheres,')}
          {_renderTextLoad('The Other Lab is made up of a team of ')}
          {_renderTextLoad('highly skilled creatives who thrive on materializing ideas.')}
        </p>
        <p className={'text-gray'}>{'<>'}</p>
        <div className="image-group-container">
          <p className='text-start-intro'>CONCOCTING</p>
          <p className='text-end-intro'>CHAOS</p>
          <img src={IMAGE_CIRCLES} alt={""} className={'image__circle-home-intro'}/>
          <div className={'image-home-intro'}>
            <Lottie
              options={defaultOptions}
              width={'100%'}
              height={'auto'}
              isClickToPauseDisabled
            />
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className={'div-loading-container'}>
      {_renderContent()}
      {/*<FooterMobile*/}
      {/*  footerType={PAGE_MOBILE.INTRO}*/}
      {/*  handlePrevStep={handlePrevStep}*/}
      {/*  handleNextStep={handleNextStep}*/}
      {/*/>*/}
    </div>
  )
}

import {combineReducers} from 'redux';
import sizeContent, {SizeContentType} from './sizeContent';
import projectList from './projectList';
import {ProjectModel} from '../../models/Project';
import isFirstLoad from './isFirstLoad';

export type RootState = {
  sizeContent: SizeContentType;
  projectList: ProjectModel[];
  isFirstLoad: boolean;
}

const rootReducer = combineReducers({
  sizeContent,
  projectList,
  isFirstLoad,
});

export default rootReducer;

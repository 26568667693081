import {Navigate} from "react-router-dom"

interface Props {
  redirect?: string
}

const NotFoundRouter = (props: Props) => {
  const {redirect} = props;

  return <Navigate to={redirect ?? ''}/>
}
export default NotFoundRouter

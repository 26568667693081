
import './content.scss';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import ImageBox from './ImageBox';
import LineHome from './LineHome';
import { RandomReveal } from 'react-random-reveal';
import { handleWheel } from "../../../ultils/common";
import { changeFirstLoad } from '../../../redux/actions/isFistLoad';

const Content = () => {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const width = useSelector((state: RootState) => state.sizeContent.width);
  const height = useSelector((state: RootState) => state.sizeContent.height);
  const isFirstLoad = useSelector((state: RootState) => state.isFirstLoad);
  const widthTd = height * 0.8 / 3;
  const [showContent, setShowContent] = useState(false);
  const [showProject, setShowProject] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    dispatch(changeFirstLoad());
    setTimeout(() => setShowImage(true), isFirstLoad ? 2000 : 0);
    setTimeout(() => setShowContent(true), isFirstLoad ? 2500 : 1000);
    setTimeout(() => setShowProject(true), isFirstLoad ? 5000 : 2500);
    ref.current.addEventListener('wheel', handleWheel);
  }, []);

  useEffect(() => {
    const handleWheel = (e: any) => {
      if (e.deltaX !== 0) {
        e.preventDefault();
      }
    };
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const _renderTd = () => {
    return (
      <td className={'td-table-container'}
        style={{ width: widthTd, height: widthTd }}
      />
    )
  };

  const _renderImageItem = (gif1: string, gif2: string, time1: number, time2: number, className1: string, className2: string, timeShow?: number) => {
    return (
      <ImageBox
        widthTd={widthTd}
        gif1={gif1}
        gif2={gif2}
        time1={time1}
        time2={time2}
        className1={className1}
        className2={className2}
        timeShow={timeShow}
      />
    )
  };

  const _renderTr = () => {
    return (
      <tr>
        {[0, 1].map(item => {
          return (
            <td className={'td-table-container'}
              style={{ width: widthTd, height: widthTd }}
              key={item}
            />
          )
        })}
        {_renderImageItem('EXO', 'SUISAI_GIF', 3, 5, 'img-gif-first', 'img-gif-first')}
        {_renderImageItem('TOWERBOX', 'SKILL_FEATURE', 4, 7, 'img-gif-first', 'img-gif-first', 0)}
      </tr>
    )
  };

  const _renderTrFirst = () => {
    return (
      <tr>
        {_renderImageItem('ARC_LONG', 'CARNIVAL', 3, 5, 'img-gif-first2', 'img-gif-first')}
        {_renderTd()}
        {_renderImageItem('BIORE_GIF', 'MEEP_LONG', 4, 6, 'img-gif-first', 'img-gif-first2', 3000)}
        {_renderTd()}
      </tr>
    )
  };

  const _renderTrLast = () => {
    return (
      <tr>
        {_renderImageItem('BLOB_LONG', 'MALING', 3, 6, 'img-gif-first2', 'img-gif-first', 3000)}
        {_renderTd()}
        {_renderImageItem('MOONCAKE_LONG', 'BUZZ', 4, 4, 'img-gif-first2', 'img-gif-first', 7000)}
        {_renderTd()}
      </tr>
    )
  };

  const _renderTable = () => {
    return (
      <table>
        <tbody>
          {_renderTrFirst()}
          {_renderTr()}
          {_renderTrLast()}
        </tbody>
      </table>
    )
  };

  const _renderTextAnimation = (text: string) => {
    return (
      <p>
        <RandomReveal
          isPlaying
          duration={1.5}
          characters={text}
          revealDuration={1}
        />
      </p>
    )
  };

  const _renderTextHome = () => {
    return (
      <div
        className='text-title'
        style={{ fontSize: width / 35, animation: 'fadeInContent 1s ease-in-out' }}
      >
        {_renderTextAnimation('Experimentation')}
        {_renderTextAnimation('in progress ...')}
      </div>
    )
  };

  const _renderProject = () => {
    return (
      <div className={'table-container'}>
        {_renderTable()}
      </div>
    )
  };

  return (
    <div ref={ref} className='experimentation-container'>
      {showContent && _renderTextHome()}
      {showProject && _renderProject()}
      <LineHome row={2} />
    </div>
  )
};

export default Content;
